import React from "react";
import DocsList from "./docs-list";
import { useAuth0 } from "../../auth0";
import Navigation from "../../components/navigation";

const Dash = () => {
  return (
    <div>
      <Navigation />
      <DocsList />
    </div>
  );
};

export default Dash;
