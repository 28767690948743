import React from "react";
import { Link } from "@reach/router";
import { useAuth0 } from "../auth0";

const Navigation = (...props) => {
  const { isAuthenticated, logout, user, loginWithRedirect } = useAuth0();

  return (
    <nav sx={{ background: "white", boxShadow: 1 }}>
      <ul
        sx={{
          maxWidth: 1024,
          display: "flex",
          listStyleType: "none",
          margin: "0 auto",
          padding: 0,
          "& a": {
            padding: 2,
            paddingBottom: 1,

            display: "block",
            color: "black",
            textDecoration: "none",
            borderBottom: "4px solid white",

            "&:hover": {
              borderBottomColor: "#075e8c"
            }
          }
        }}
      >
        <li>
          <Link
            to="/dashboard"
            getProps={({ isCurrent }) => {
              // the object returned here is passed to the
              // anchor element's props
              return {
                style: {
                  borderBottomColor: isCurrent ? "black" : "white"
                }
              };
            }}
          >
            Dashboard
          </Link>
        </li>
        {user && user.nickname && (
          <li>
            <a href="#">{user.nickname}</a>
          </li>
        )}
        <li>
          {isAuthenticated ? (
            <button onClick={() => logout()}>Log out</button>
          ) : (
            <button
              onClick={() => {
                loginWithRedirect({
                  appState: { targetUrl: `/dashboard` }
                });
              }}
            >
              Log in
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
