/** @jsx jsx */
import { jsx } from "theme-ui";
import { useAuth0 } from "../auth0";

const Login = ({ location }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0() || {};

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     loginWithRedirect({
  //       appState: { targetUrl: location.pathname }
  //     });
  //   }
  // }, [isAuthenticated, loginWithRedirect, location.pathname]);

  return (
    <div
      sx={{
        position: "absolute",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {isAuthenticated ? (
        <div>this shouldn't show up because you're already logged in</div>
      ) : (
        <button
          onClick={() => {
            loginWithRedirect({
              appState: { targetUrl: location.pathname }
            });
          }}
        >
          Log in here!
        </button>
      )}
    </div>
  );
};

export default Login;
