import React from "react";
import { Router } from "@reach/router";
import { useAuth0 } from "../auth0";
import Create from "../components/create";
import Dashboard from "../components/dashboard";
import Login from "../components/login";
import Layout from "../components/layout";

const SectorApp = props => {
  const auth = useAuth0();
  if (!auth || auth.loading) {
    return (
      <div>
        <nav sx={{ background: "white", boxShadow: 1, height: "50px" }} />
        <span>Loading...</span>
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <Router>
        <Login default />
      </Router>
    );
  }

  return (
    <Layout>
      <Router basepath="/">
        <Dashboard path="/dashboard" />
        <Create path="/create/:workspace/:id" />
        <Create path="/create/:workspace" />
      </Router>
    </Layout>
  );
};

export default SectorApp;
