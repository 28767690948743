/** @jsx jsx */
import { jsx, ThemeProvider, Styled } from "theme-ui";
import tokens from "@sector/tokens";

export default ({ children }) => {
  return (
    <ThemeProvider theme={tokens}>
      <Styled.root>{children}</Styled.root>
    </ThemeProvider>
  );
};
