/** @jsx jsx */
import { jsx } from "theme-ui";
import UpdateMdx from "./update-mdx";

const Create = ({ workspace, id }) => {
  // if (!id) {
  //   return (
  //     <div>
  //       <h2>new post!</h2>
  //       <textarea />
  //     </div>
  //   );
  // }
  return <UpdateMdx workspace={workspace} id={id} />;
};

export default Create;
