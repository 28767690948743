/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState } from "react";
import { Router, Link, navigate } from "@reach/router";
import { useQuery, useMutation, useApolloClient, gql } from "@apollo/client";
import { parseToMdxast } from "@sector/mdx-editor/mdx-parse";
import matchSorter from "match-sorter";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const GET_DOCS = gql`
  query getDocs {
    allWorkspaces {
      id
      displayName
      allMdx {
        id
        title
        contentType
      }
    }
  }
`;

const CREATE_SPACE = gql`
  mutation CreateSpace {
    createWorkspace {
      id
    }
  }
`;

const CREATE_MDX = gql`
  mutation CreatePost(
    $workspace: String!
    $contentType: String!
    $content: String!
  ) {
    createMdx(
      workspace: $workspace
      contentType: $contentType
      content: $content
    ) {
      id
    }
  }
`;

const UPDATE_MDX_METADATA = gql`
  mutation UpdateMdxMetadata(
    $id: ID!
    $contentType: String!
    $workspace: String!
  ) {
    updateMdxMetadata(
      id: $id
      workspace: $workspace
      contentType: $contentType
    ) {
      id
    }
  }
`;

function DocsList() {
  const client = useApolloClient();
  const { loading, error, data, refetch } = useQuery(GET_DOCS);
  const [createSpace, res] = useMutation(CREATE_SPACE);
  const [createPost, createMdxData] = useMutation(CREATE_MDX);
  const [updateMdxMeta] = useMutation(UPDATE_MDX_METADATA);
  const [searchFilter, setSearchFilter] = useState("");

  if (loading) return <p>Loading ...</p>;
  if (error) {
    console.log(error);
    return <div>something went wrong</div>;
  }
  const spacesCount = data.allWorkspaces.length;
  if (spacesCount === 0) {
    return (
      <div>
        <p>You don't have access to any spaces yet:</p>
        <button
          onClick={async () => {
            const workspace = await createSpace();
            refetch();
          }}
        >
          Create your Space
        </button>
      </div>
    );
  }

  return (
    <div>
      {data.allWorkspaces.map(({ id: workspaceId, displayName, allMdx }) => {
        const results = matchSorter(allMdx, searchFilter, {
          keys: ["title"]
        });
        return (
          <div key={workspaceId}>
            <div
              sx={{
                backgroundImage: `linear-gradient(43deg,#4158D033 0%,#C850C033 46%,#FFCC7033 100%)`,
                padding: 4
              }}
            >
              <div sx={{ variant: "styles.container" }}>
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <h2 sx={{ margin: 0, marginRight: 4, marginBottom: 2 }}>
                    {displayName}
                  </h2>
                  <button
                    sx={{ height: "2rem" }}
                    onClick={async () => {
                      // <Link to={`/create/${workspaceId}`}>create new document</Link>

                      const result = await createPost({
                        variables: {
                          workspace: workspaceId,
                          content: JSON.stringify(
                            parseToMdxast(
                              `# Planting some thoughts

in my digital garden`
                            )
                          ),
                          contentType: "note"
                        }
                      });
                      navigate(
                        `/create/${workspaceId}/${result.data.createMdx.id}`
                      );
                    }}
                  >
                    {createMdxData.loading ? "Creating" : "Create New Document"}
                  </button>
                </div>
                <label>
                  <span sx={{ marginRight: 2 }}>Filter:</span>
                  <input onChange={e => setSearchFilter(e.target.value)} />
                </label>
              </div>
            </div>
            <DragDropContext
              onDragEnd={async result => {
                const { draggableId, source, destination } = result;

                // dropped outside the list
                if (
                  !destination ||
                  source.droppableId === destination.droppableId
                ) {
                  return;
                }
                console.log("dragged", workspaceId, result);
                let newContentType = undefined;
                switch (destination.droppableId) {
                  case "blog-post":
                    newContentType = "blog-post";
                    break;
                  case "note":
                    newContentType = "note";
                    break;
                }
                if (newContentType) {
                  await updateMdxMeta({
                    variables: {
                      id: draggableId,
                      workspace: workspaceId,
                      contentType: newContentType
                    }
                  });
                  await refetch();
                }
              }}
            >
              <div sx={{ variant: "styles.container" }}>
                <div
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: 3
                  }}
                >
                  <Droppable droppableId="blog-post">
                    {(droppableProvided, snapshot) => (
                      <div ref={droppableProvided.innerRef}>
                        <h3>Blog Posts</h3>

                        <ul
                          key={workspaceId}
                          sx={{
                            listStyleType: "none",
                            margin: 0,
                            padding: 0,
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fill, minmax(200px, max-content))",
                            gridGap: 2
                            // zIndex: 1
                          }}
                        >
                          {results
                            .filter(
                              ({ contentType }) => contentType === "blog-post"
                            )
                            .map(({ id, title, contentType }, index) => (
                              <Draggable
                                key={id}
                                draggableId={id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    key={id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      marginTop: 1,
                                      padding: 2,
                                      backgroundColor: "#fff",
                                      borderRadius: 1,
                                      // border: "1px solid #d8dee180",

                                      boxShadow: `0 0.1px 0.2px -1px rgba(0, 0, 0, 0.02),
                                      0 0.1px 0.5px -1px rgba(0, 0, 0, 0.028),
                                      0 0.3px 1px -1px rgba(0, 0, 0, 0.035),
                                      0 0.4px 1.8px -1px rgba(0, 0, 0, 0.042),
                                      0 0.8px 3.3px -1px rgba(0, 0, 0, 0.05),
                                      0 2px 8px -1px rgba(0, 0, 0, 0.07)`,
                                      "&:hover": {
                                        boxShadow: `0 0.1px 0.2px -1px rgba(187, 232, 254, 0.02),
                                      0 0.1px 0.5px -1px rgba(187, 232, 254, 0.028),
                                      0 0.3px 1px -1px rgba(187, 232, 254, 0.035),
                                      0 0.4px 1.8px -1px rgba(187, 232, 254, 0.042),
                                      0 0.8px 3.3px -1px rgba(187, 232, 254, 0.05),
                                      0 2px 8px -1px rgba(187, 232, 254, 0.07)`
                                      }
                                    }}
                                  >
                                    <ContentCard
                                      id={id}
                                      title={title}
                                      type={contentType}
                                      workspace={workspaceId}
                                      workspaceDisplayName={displayName}
                                    />
                                  </li>
                                )}
                              </Draggable>
                            ))}
                          {droppableProvided.placeholder}
                        </ul>
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="note">
                    {(droppableProvided, snapshot) => (
                      <div ref={droppableProvided.innerRef}>
                        <h3>Notes</h3>
                        <ul
                          key={workspaceId}
                          sx={{
                            listStyleType: "none",
                            margin: 0,
                            padding: 0,
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fill, minmax(200px, max-content))",
                            gridGap: 2
                            // zIndex: 1
                          }}
                        >
                          {results
                            .filter(({ contentType }) => contentType === "note")
                            .map(({ id, title, contentType }, index) => (
                              <Draggable
                                key={id}
                                draggableId={id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    key={id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      marginTop: 1,
                                      padding: 2,
                                      backgroundColor: "#fff",
                                      borderRadius: 1,
                                      // border: "1px solid #d8dee180",

                                      boxShadow: `0 0.1px 0.2px -1px rgba(0, 0, 0, 0.02),
                                      0 0.1px 0.5px -1px rgba(0, 0, 0, 0.028),
                                      0 0.3px 1px -1px rgba(0, 0, 0, 0.035),
                                      0 0.4px 1.8px -1px rgba(0, 0, 0, 0.042),
                                      0 0.8px 3.3px -1px rgba(0, 0, 0, 0.05),
                                      0 2px 8px -1px rgba(0, 0, 0, 0.07)`,
                                      "&:hover": {
                                        boxShadow: `0 0.1px 0.2px -1px rgba(187, 232, 254, 0.02),
                                      0 0.1px 0.5px -1px rgba(187, 232, 254, 0.028),
                                      0 0.3px 1px -1px rgba(187, 232, 254, 0.035),
                                      0 0.4px 1.8px -1px rgba(187, 232, 254, 0.042),
                                      0 0.8px 3.3px -1px rgba(187, 232, 254, 0.05),
                                      0 2px 8px -1px rgba(187, 232, 254, 0.07)`
                                      }
                                    }}
                                  >
                                    <ContentCard
                                      id={id}
                                      title={title}
                                      type={contentType}
                                      workspace={workspaceId}
                                      workspaceDisplayName={displayName}
                                    />
                                  </li>
                                )}
                              </Draggable>
                            ))}
                          {droppableProvided.placeholder}
                        </ul>
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </DragDropContext>
          </div>
        );
      })}
    </div>
  );
}

export default DocsList;

const ContentCard = ({ id, title, type, workspace, workspaceDisplayName }) => {
  return (
    <div>
      <Link
        to={`/create/${workspace}/${id}`}
        sx={{ fontSize: 1, textDecoration: "none", color: "text" }}
      >
        {title}
      </Link>
    </div>
  );
};
