import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import MDXEditor, { FallbackEditor } from "@sector/mdx-editor";
import { fromSlate } from "@sector/mdx-editor/mdx-parse";
import cloneDeep from "lodash.clonedeep";
import { useDebounce } from "react-use";
import { Link } from "@reach/router";

import Navigation from "../../navigation";
import FormMdxContentType from "./form-content-type";
import FormMdxExcerpt from "./form-excerpt";

import NewBlogPostForm from "./new-blog-post";

const GET_DOC = gql`
  query getDoc($id: ID!, $workspace: String!) {
    mdx(id: $id, workspace: $workspace) {
      id
      content
      title
      contentType
      __typename
    }
  }
`;

const UPDATE_MDX_CONTENT = gql`
  mutation UpdateMdxContent($id: ID!, $workspace: String!, $content: String!) {
    updateMdxContent(id: $id, workspace: $workspace, content: $content) {
      id
    }
  }
`;

const UPDATE_MDX_METADATA = gql`
  mutation UpdateMdxMetadata($id: ID!, $title: String, $workspace: String!) {
    updateMdxMetadata(id: $id, workspace: $workspace, title: $title) {
      id
    }
  }
`;

class FallbackErrorBoudnary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error) {
    console.log("getDerivedStateFromError, Fallback", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return <p>value is not valid</p>;
    } else {
      return this.props.children;
    }
  }
}
class EditorErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      content: JSON.stringify(this.props.content, null, 2),
      userModifiedContent: this.props.content,
      i: 0
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      console.log(this.state.i);
      return (
        <div>
          <h1>Fancy Editor had an issue</h1>
          <button
            onClick={() => {
              this.props.updateMdxContent(this.state.userModifiedContent);
            }}
            sx={{ background: "red", color: "white", marginBottom: "1rem" }}
          >
            Big Dangerous Save Button
          </button>
          <p>
            Auto-saving is disabled. You're in "raw mode": be careful! All of
            your data is safe, if you're concerned please contact us with the
            url you're currently on and we'll help you out as fast as we can.
          </p>
          <div sx={{ display: "flex" }}>
            <textarea
              cols={80}
              rows={500}
              defaultValue={JSON.stringify(
                this.state.userModifiedContent,
                null,
                2
              )}
              onChange={e => {
                console.log(e.target.value);
                try {
                  console.log("parsing");
                  const newAst = JSON.parse(e.target.value);
                  console.log("setting", newAst);
                  this.setState({
                    userModifiedContent: newAst,
                    i: this.state.i + 1
                  });
                } catch (e) {
                  console.log("json failed to parse", e);
                }
              }}
            />
            <FallbackErrorBoudnary key={this.state.i}>
              <FallbackEditor mdxast={this.state.userModifiedContent} />
            </FallbackErrorBoudnary>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const UpdateMdx = ({ workspace, id }) => {
  const [updateMdxContent] = useMutation(UPDATE_MDX_CONTENT);
  const { loading, error, data } = useQuery(GET_DOC, {
    variables: { id: id, workspace },
    skip: !id
  });

  const defaultValue =
    data && data.mdx && data.mdx.content && JSON.parse(data.mdx.content);
  const [value, setValue] = useState();

  const [, cancel] = useDebounce(
    () => {
      if (Boolean(id) && value) {
        console.log("saving value", value);
        // if we have an id, update the existing mdx doc
        updateMdxContent({
          variables: {
            id: id,
            workspace,
            content: JSON.stringify(
              fromSlate({
                type: "root",
                children: cloneDeep(value)
              })
            )
          }
        });
      }
    },
    1000,
    [value]
  );
  if (loading) return <p>Loading ...</p>;
  if (error) {
    console.log("GET_DOC", error);
    return (
      <div>
        <Navigation />
        <div>
          <p>Something went wrong</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navigation />
      <div sx={{ variant: "styles.container" }}>
        <div
          sx={{ display: "grid", gridTemplateColumns: "1fr 200px", gridGap: 3 }}
        >
          <div
            sx={{
              variant: "styles.sheet",
              // paddingTop: 2,
              paddingBottom: 2,
              borderRadius: 1
            }}
          >
            <div
              sx={{
                backgroundColor:
                  data.mdx.contentType === "blog-post" ? "blogPost" : "note",
                paddingLeft: 4,
                paddingRight: 4,
                borderTopLeftRadius: 1,
                borderTopRightRadius: 1
              }}
            >
              <div
                sx={{
                  maxWidth: 1024,
                  margin: "auto",
                  color: "white",
                  fontWeight: 500,
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <UpdateTitle
                  maybeTitle={data && data.mdx && data.mdx.title}
                  id={id}
                  workspace={workspace}
                />

                <nav>
                  <ul
                    sx={{
                      display: "flex",
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                      "& a": {
                        padding: 2,
                        paddingBottom: 1,

                        display: "block",
                        color: "white",
                        textDecoration: "none",
                        borderBottom: "4px solid transparent",

                        "&:hover": {
                          borderBottomColor: "white"
                        }
                      }
                    }}
                  >
                    <li>
                      <Link
                        to={`/create/${workspace}/${id}`}
                        getProps={({ isCurrent }) => {
                          // the object returned here is passed to the
                          // anchor element's props
                          return {
                            style: {
                              borderBottomColor: isCurrent
                                ? "white"
                                : "transparent"
                            }
                          };
                        }}
                      >
                        Write
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/create/${workspace}/${id}/meta`}
                        getProps={({ isCurrent }) => {
                          // the object returned here is passed to the
                          // anchor element's props
                          return {
                            style: {
                              ...(isCurrent
                                ? { borderBottomColor: "white" }
                                : {})
                            }
                          };
                        }}
                      >
                        Metadata
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              sx={{
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 1,
                paddingBottom: 6
              }}
            >
              <EditorErrorBoundary
                content={value || defaultValue}
                updateMdxContent={rescuedMdxContent => {
                  updateMdxContent({
                    variables: {
                      id: id,
                      workspace,
                      content: JSON.stringify(rescuedMdxContent)
                    }
                  });
                }}
              >
                <MDXEditor
                  mdast={defaultValue}
                  onChange={mdxastSlate => {
                    setValue(mdxastSlate);
                  }}
                />
              </EditorErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UpdateTitle = ({ id, workspace, maybeTitle }) => {
  const [updateMdxMeta] = useMutation(UPDATE_MDX_METADATA);
  const [title, setTitle] = useState(maybeTitle);
  const [, cancel] = useDebounce(
    async () => {
      if (Boolean(id) && Boolean(workspace) && title) {
        // console.log("saving title", title);
        await updateMdxMeta({
          variables: {
            id,
            workspace,
            title
          }
        });
      }
    },
    1000,
    [title]
  );
  return (
    <form sx={{ display: "flex" }}>
      <label sx={{ display: "flex", alignItems: "center" }}>
        <span>title:</span>
        <input
          placeholder="Write Title Here"
          type="text"
          value={title}
          onChange={e => {
            setTitle(e.target.value);
          }}
          sx={{
            variant: "styles.h5",
            border: "none",
            background: "transparent",
            color: "white",
            marginLeft: 2
          }}
        />
      </label>
    </form>
  );
};
export default UpdateMdx;
